<template>
	<div>
		<s-select-definition
			:def="1434"
			@getDataInitial="getAreas($event)"
			hidden
		></s-select-definition>

		<s-crud
			title="Campaña por área"
			:config="config"
			:filter="filter"
			add
			@save="save($event)"
			edit
			remove
		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col
							class="s-col-form"
						>
							<s-select-definition
								label="Tipo Cultivo"
								:def="1173"
								v-model="props.item.TypeCultive"
							></s-select-definition>
						</v-col>
						<v-col
							class="s-col-form"
						>
							<s-date
								v-model="props.item.CmaBeginDate"
								label="Fecha Inicio"
							></s-date>
						</v-col>
						<v-col
							class="s-col-form"
						>
							<s-date
								v-model="props.item.CmaEndDate"
								label="Fecha Fin"
							></s-date>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:CmaStatus="{ row }">
				<v-chip
					x-small
					:color="row.CmaStatus == 1 ? 'success' : 'warning'"
				>
					{{ row.CmaStatus == 1 ? 'Activo' : 'Inactivo' }}
				</v-chip>
			</template>
		</s-crud>

		<!-- seleccionar el área -->
		<v-dialog
			v-model="dialogArea"
			persistent
			
		>
			<v-card
				dense
				outlined
			>
				<v-card-title>
					Selecciona una área para continuar
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col
							v-for="area in areas"
							:key="area.DedValue"
						>
							<v-btn
								color="info"
								rounded
								@click="clickArea(area.DedValue)"
							>
								<span>{{
									area.DedDescription
								}}</span>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sCampaingarea from "@/services/General/CampaignAreaService";

	export default {
		data() {
			return {
				dialogArea : false,
				filter : {
					AreID : 0,
					IsAdmin : this.$fun.isAdmin() ? 1 : 0,
					TypeArea : this.typeArea
				},
				areas : [],
				typeArea : 0,
				userInfo : {},
				config: {
					service: _sCampaingarea,
					model : {
						CmaID : "ID",
						CmaBeginDate : "date",
						CmaEndDate : "date",
						CmaStatus : "int"
					},
					headers : [
						{text: "ID", value:"CmaID"},
						{text: "Área", value:"TypeAreaName"},
						{text: "Nombre", value:"CmaName"},
						{text: "Tipo Cultivo", value:"TypeCultiveName"},
						{text: "F. Inicio", value:"CmaBeginDate"},
						{text: "F. Fin", value:"CmaEndDate"},
						{text: "Estado", value:"CmaStatus"},
					]
				}
			}
		},

		methods: {
			getAreas(areas) {
				this.areas = areas;
			},

			clickArea(area)
			{
				this.filter.TypeArea = area;
				this.typeArea = area;
				this.dialogArea = false;
			},

			save(item)
			{
				item.typeArea = this.typeArea;
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
				item.AreID = this.filter.AreID;
				console.log('itemm', item);
				item.save();
				
			}
		},

		created () {
			this.userInfo = this.$fun.getUserInfo();
			if(this.userInfo != undefined || this.userInfo != null)
			{
				this.filter.AreID = this.userInfo.CtrContract.AreID;
			}

			this.dialogArea = true;
		},
	}
</script>
